import {defineStore} from "pinia";
import {ref} from "vue";

export const useLoginStore = defineStore('APP-Store', ()=>{
    let Rname = ref("default");
    let inside = ref(true);
    function AdaptationScreen(){
        if (window.innerWidth>window.innerHeight){
            //电脑/平板
            let h = 2800;
            if (window.innerHeight>2800){
                inside.value = true;
                h = window.innerHeight-2800;
            }else {
                inside.value = false;
                h = 2800 - window.innerHeight;
            }
            return h/2800;
        }else {
            //手机或平板
            let h = 1260;
            if (window.innerWidth>1260){
                inside.value = true;
                h = window.innerWidth-1260;
            }else {
                inside.value = false;
                h = 1260 - window.innerWidth;
            }
            return h/1260;
        }
    }

    function NewName(name){
        Rname.value = name;
    }
    return{
        NewName,
        AdaptationScreen,
        Rname,
        inside
    }

});