import {createRouter, createWebHashHistory} from 'vue-router';
import Login from "@/VUEcomponents/LoginScreen.vue";
import App from '@/App.vue';
import eliminate from "@/VUEcomponents/match-3game.vue";
import End from "@/VUEcomponents/End-Screen.vue"

const router = createRouter({
    mode: 'history',
    base: App,
   // history: createWebHistory(),
    history:createWebHashHistory(),
  routes: [
      {
          path: '/',
          name: 'index',
          components: {
              Game:eliminate,
              End: End,
              default:Login,
          }
      }
  ]
})

export default router
