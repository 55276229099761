<template>
  <div id="EndScreen"></div>
</template>

<script setup>
//pixi程序
import {onMounted} from "vue";
import {Application, Assets, Container, FillGradient, Graphics, Sprite, Text} from "pixi.js";
import {useLoginStore} from "@/stores/APP-Srceen.ts";


let p = useLoginStore().AdaptationScreen();
let isadd = useLoginStore().inside.valueOf();
let app = null;
//页面画布
let canvas = null;

onMounted(()=>{
  gameStart();
})
async function gameStart() {
  app = new Application();
  await app.init({
    width: isadd ? 1260 + (1260 * p) : 1260 - (1260 * p),    //画布宽度
    height: isadd ? 2800 + (2800 * p) : 2800 - (2800 * p), //画布高度
    /*width: window.innerWidth,    //画布宽度
    height: window.innerHeight, //画布高度*/
    background: {r: 255, g: 246, b: 207},   //背景颜色
    backgroundAlpha: 1,  //背景透明度
    antialias: true,  //抗锯齿
  });
  //画布CSS属性配置
  canvas = app.canvas;
  canvas.style.position = 'fixed';
  canvas.style.top = '50%';
  canvas.style.left = '50%';
  canvas.style.transform = 'translate(-50%, -50%)';
  //将画布添加到指定元素下
  //指定元素一定要被<Suspense> 包裹!!!!!!!!!!!!!!!!!!!!
  document.getElementById("EndScreen").appendChild(app.canvas);

//被污染的背景图
  const setting = new Graphics();
  // 设置渐变颜色
  const colorStops = [0xffffff, 0x7ACE9C, 0x3B284F];
  const offsets = [1, 0.7, 0];
  // Create a fill gradient
  const gradientFill = new FillGradient(0, 0, 0, window.innerHeight);
  for (let a = 0; a < colorStops.length; a++) {
    gradientFill.addColorStop(offsets[a], colorStops[a]);
  }
  setting.rect(0, 0, window.innerWidth + 100, window.innerHeight);
  setting.fill(gradientFill)
  app.stage.addChild(setting);

  const vendingTexture = await Assets.load("./property/vending.png")
  const vending = new Sprite(vendingTexture);
  vending.anchor.set(0, 1);
  vending.x = 0;
  vending.y = canvas.height;
  vending.width = canvas.width;
  vending.height = canvas.width / 0.7;
  app.stage.addChild(vending);

  const filter = new Graphics();
  filter.rect(0 , 0 , canvas.width , canvas.height/1.2);
  filter.fill({ color: 0x000000, alpha: 0.5 });
  app.stage.addChild(filter);

  const button = await Assets.load("./property/buuton.png")
  const button1 = new Sprite(button);
  button1.anchor.set(0.5);
  button1.x = canvas.width / 2;
  button1.y = canvas.height /2;
  button1.width = canvas.width / 2.8;
  button1.height = canvas.width /2.8 / 2.5;
  button1.alpha = 1;
  app.stage.addChild(button1);
  button1.cursor = 'pointer';
  button1.eventMode = 'dynamic';
  button1.interactive = true;
  button1.on("pointerdown" , ()=>{
    useLoginStore().NewName("default");
  })

  const ntegralText = new Text({
    text: "再玩一次",
  });
  ntegralText.anchor.set(0.5);
  ntegralText.x = canvas.width / 2;
  ntegralText.y = canvas.height / 2;
  ntegralText.style.fontSize = canvas.height / 18 / 2;
  ntegralText.style.fill = 0x000000;
  ntegralText.alpha =1;
  app.stage.addChild(ntegralText);
  ntegralText.on("pointerdown" , ()=>{
    useLoginStore().NewName("default");
  })
  const tegralText = new Text({
    text: "恭喜获胜",
  });
  tegralText.anchor.set(0.5);
  tegralText.x = canvas.width / 2;
  tegralText.y = canvas.height / 3.5;
  tegralText.style.fontSize = canvas.height / 10 / 2;
  tegralText.style.fill = 0xFFFFFF;
  tegralText.alpha = 1;
  app.stage.addChild(tegralText);


  //标题区域
  const titles = new Container();
  app.stage.addChild(titles);
  //标题
  const titleTexture = await Assets.load("./property/gametitle.png")
  const title = new Sprite(titleTexture)
  title.anchor.set(0.5);
  title.x = canvas.width / 2;
  title.y = canvas.width / 5.5;
  title.width = canvas.width / 1.8;
  title.height = canvas.width / 1.8 / 2.5;
  titles.addChild(title);

  //积分
  //文本
  const integralText = new Text({
    text: "分数：30",
  });
  integralText.x = canvas.width / 2 / 1.5;
  integralText.y = canvas.height / 1.14;
  integralText.style.fontSize = canvas.height / 15 / 2;
  integralText.style.fill = 0xFFFFFF;
  app.stage.addChild(integralText);

}
</script>


<style scoped>

</style>