<template>
  <div id="game"></div>
</template>

<script setup>
import {Application, Assets, Container, FillGradient, Graphics, Sprite,Text} from "pixi.js";
import {useLoginStore} from "@/stores/APP-Srceen.ts";
import {onMounted} from "vue";
import {gsap} from "gsap";

/**全局数据*/
let p = useLoginStore().AdaptationScreen();
let isadd = useLoginStore().inside.valueOf();
//pixi程序
let app = null;
//页面画布
let canvas = null;
//游戏数据
let gameData = new GameData();

function GameData(){
  let textures = [];
  let texturesCount= [0,0,0,0,0,0,0];
  let oneClick = null;
  let twoClick = null;
  let  isdown = true;
  let elimintitems = [];
  let chessboard = new Chessboard();
  let frame = 0;
  let score = 0;
  let double = 0;
  return{
    //纹理组
    textures,
    //各纹理存储数量
    texturesCount,
    //第一次点击
    oneClick,
    //第二次点击
    twoClick,
    //是否可点击
    isdown,
    //待消除项  数组存放 @Happy
    elimintitems,
    //棋盘对象
    chessboard,
    //网页进行帧数   1秒60帧  每60表示过去1秒  从网页加载开始计时
    frame,
    //的分
    score,
    //连击
    double,
  }
}
/**棋盘对象*/
function Chessboard(){
  let container = null;
  let HappyEs = [];
  let nullhappy = new Sprite();
  return{
    //容器
    container,
    //精灵组
    HappyEs,
    //空精灵
    nullhappy,
  }
}

/**精灵对象*/
function Happy(){
 let happy = null;
 let WLType = null;
 let HL = [];
 let rebirth = null;
 return{
   //精灵
   happy,
   //精灵类型
   WLType,
   //坐标
   HL,
   //重生点
   rebirth,
 }
}

onMounted(()=>{
  gameStart();
})

//异步任务
async function gameStart() {

  //纹理
  const h0 = await Assets.load("./property/happy/hippy0.png");
  const h1 = await Assets.load("./property/happy/hippy1.png");
  const h2 = await Assets.load("./property/happy/hippy2.png");
  const h3 = await Assets.load("./property/happy/hippy3.png");
  const h4 = await Assets.load("./property/happy/hippy4.png");
  const h5 = await Assets.load("./property/happy/hippy6.png");
  const h6 = await Assets.load("./property/happy/hippy7.png");
//空白纹理
  const h7 = await Assets.load("./property/happy/hippy5.png");

  gameData.chessboard.nullhappy = new Sprite(h7).name = [0, 0];

  app = new Application();
  await app.init({
    width: isadd ? 1260 + (1260 * p) : 1260 - (1260 * p),    //画布宽度
    height: isadd ? 2800 + (2800 * p) : 2800 - (2800 * p), //画布高度
    /*width: window.innerWidth,    //画布宽度
    height: window.innerHeight, //画布高度*/
    background: {r: 255, g: 246, b: 207},   //背景颜色
    backgroundAlpha: 1,  //背景透明度
    antialias: true,  //抗锯齿
  });
  //画布CSS属性配置
  canvas = app.canvas;
  canvas.style.position = 'fixed';
  canvas.style.top = '50%';
  canvas.style.left = '50%';
  canvas.style.transform = 'translate(-50%, -50%)';
  //将画布添加到指定元素下
  //指定元素一定要被<Suspense> 包裹!!!!!!!!!!!!!!!!!!!!
  document.getElementById("game").appendChild(app.canvas);

  //被污染的背景图
  const setting = new Graphics();
  // 设置渐变颜色
  const colorStops = [0xffffff, 0x7ACE9C, 0x3B284F];
  const offsets = [1, 0.7, 0];
  // Create a fill gradient
  const gradientFill = new FillGradient(0, 0, 0, window.innerHeight);
  for (let a = 0; a < colorStops.length; a++) {
    gradientFill.addColorStop(offsets[a], colorStops[a]);
  }
  setting.rect(0, 0, window.innerWidth + 100, window.innerHeight);
  setting.fill(gradientFill)
  app.stage.addChild(setting);
//售卖机
  const vendingTexture = await Assets.load("./property/vending.png")
  const vending = new Sprite(vendingTexture);
  vending.anchor.set(0, 1);
  vending.x = 0;
  vending.y = canvas.height;
  vending.width = canvas.width;
  vending.height = canvas.width / 0.7;
  app.stage.addChild(vending);
  //标题区域
  const titles = new Container();
  app.stage.addChild(titles);
  //标题
  const titleTexture = await Assets.load("./property/gametitle.png")
  const title = new Sprite(titleTexture)
  title.anchor.set(0.5);
  title.x = canvas.width / 2;
  title.y = canvas.width / 5.5;
  title.width = canvas.width / 1.8;
  title.height = canvas.width / 1.8 / 2.5;
  titles.addChild(title);
  //标题滤镜
  const titleMTexture = await Assets.load("./property/titlemark.png")
  const titlem = new Sprite(titleMTexture)
  titlem.anchor.set(0.5);
  titlem.x = canvas.width / 2;
  titlem.y = canvas.width / 5.5;
  titlem.width = canvas.width / 1.8;
  titlem.height = canvas.width / 1.8 / 2.5;
  titles.addChild(titlem);
  //积分
  //文本
  const integralText = new Text({
    text: "分数：" + gameData.score,
  });
  integralText.x = canvas.width / 2 / 1.5;
  integralText.y = canvas.height / 1.14;
  integralText.style.fontSize = canvas.height / 15 / 2;
  integralText.style.fill = 0xFFFFFF;
  app.stage.addChild(integralText);

  gameData.chessboard.container = new Container();
  gameData.chessboard.container.x = canvas.width / 13;
  gameData.chessboard.container.y = canvas.height - (canvas.width / 0.7) + canvas.height / 13.5;
  gameData.chessboard.container.width = canvas.width / 1.1;
  gameData.chessboard.container.height = canvas.width / 1.1;
  app.stage.addChild(gameData.chessboard.container);


  //纹理数组
  gameData.textures = [h0, h1, h2, h3, h4, h5, h6]


  //高亮提示容器
  const outline = new Container();
  outline.x = canvas.width / 13;
  outline.y = canvas.height - (canvas.width / 0.7) + canvas.height / 13.5;
  outline.width = canvas.width / 1.1;
  outline.height = canvas.width / 1.1;
  app.stage.addChild(outline);
  //第一次点击高亮
  const outline1 = new Graphics();
  let color1 = 0xFFF6CF;
  outline1.roundRect(canvas.width / 2 - (canvas.width / 1.2) / 2, canvas.height / 1.25, canvas.width / 1.18 / 8.2, canvas.width / 1.18 / 8.2, 16.691);
  outline1.stroke({width: canvas.width / 180, color1, alpha: 0});
  outline.addChild(outline1);
  
  //第二次点击高亮
  const outline2 = new Graphics();
  let color2 = 0xED965A;
  outline2.roundRect(canvas.width / 2 - (canvas.width / 1.2) / 2, canvas.height / 1.25, canvas.width / 1.18 / 8.2, canvas.width / 1.18 / 8.2, 16.691);
  outline2.stroke({width: canvas.width / 180, color2, alpha: 0});
  outline.addChild(outline2);

  //初始化游戏happy布局
  for (let i = 1; i < 66; i++) {
    if (i<65){
      //随机布局并放置快乐
      const rh = RandomHappy();
      CreateHappy(canvas, rh, i, true);
    }else {
     /* console.log(gameData.elimintitems);
      gameData.isdown = false;0
      //自动消除符合规则的项目
      for (const Happye of gameData.chessboard.HappyEs){
        console.log(Happye);
        Detectionelimintitem(Happye);
      }
      console.log(gameData.elimintitems);
      if (gameData.elimintitems.length > 2) {
        gameData.elimintitems = [...new Set(gameData.elimintitems)];
        console.log(gameData.elimintitems.length);
        elimintitem();
      }
      gameData.isdown = true;*/
    }
  }

  //happy布局随机布置 随机获取可使用的纹理
  function RandomHappy() {
    const RandomInt = getRandomInt(0, 6);
    let max = 12;
    if (gameData.texturesCount[RandomInt] === h0) {
      max = 11;
    }
    if (gameData.texturesCount[RandomInt] < max) {
      gameData.texturesCount[RandomInt] = gameData.texturesCount[RandomInt] + 1;
      return RandomInt;
    }
    return RandomHappy();
  }

  //获取随机纹理索引
  function getRandomInt(min, max) {
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }

//indexes 纹理   indexe 元素
  function CreateHappy(canvas, Textureindex, Happyindex, init) {
    // 确保 happytextures 已经定义
    if (!gameData.textures || !gameData.textures[Textureindex]) {
      console.error('Texture not found for index:', Textureindex);
      return;
    }
    const texture = gameData.textures[Textureindex];
    const happy = new Sprite(texture);
    const x = 0;
    const y = 0;

    //列索引
    let xx = Happyindex;
    //行索引
    let yy = 1;
    if (init) {
      //计算行列
      if (Happyindex > 8) {
        if (Happyindex % 8 === 0) {
          xx = 8;
          yy = Happyindex / 8;
        } else {
          xx = Happyindex % 8;
          yy = yy + (Happyindex - xx) / 8;
        }
      }
    } else {
      xx = Happyindex[0];
      yy = Happyindex[1];
    }

    happy.anchor.set(0.5);
    happy.width = canvas.width / 1.18 / 8.2;
    happy.height = canvas.width / 1.18 / 8.2;
    happy.y = y + (yy * happy.height.valueOf() - happy.height.valueOf()) + canvas.width / 200 + ((canvas.width / 1.18 / 8.2) / 2);
    happy.x = x + (xx * happy.width.valueOf() - happy.width.valueOf()) + canvas.width / 200 + ((canvas.width / 1.18 / 8.2) / 2);
    happy.cursor = 'pointer';
    happy.eventMode = 'dynamic';
    happy.interactive = true;
    let happye = new Happy();
    if (init) {
      happye.happy = happy;
      happye.HL = [xx, yy];
      happye.WLType = Textureindex;
    } else {
      happy.width = 0;
      happy.height = 0;
      if (gameData.chessboard.HappyEs.indexOf(HlgetHappy(Happyindex)) !== -1) {
        happye = HlgetHappy(Happyindex);
      } else {
        happye.HL = [xx, yy];
      }
      happye.happy = happy;
      happye.WLType = Textureindex;
      happye.rebirth =
          gsap.to(happy, {
            width: canvas.width / 1.18 / 8.2,
            height: canvas.width / 1.18 / 8.2,
            duration: 20 / 60,
            paused: true,
          });
    }
    gameData.chessboard.HappyEs.push(happye);


    //点击事件
    happy.on("pointerdown", (e) => {

      const Happyo = getHappy(happy);
      if (Happyo === null) {
        return;
      }
      if (e.button === 2) {

        return;
      }
      if (e.button === 1) {
        //console.log(Happyo.HL);
        //console.log("类型"+Happyo.WLType)
        gameData.score++;
        return;
      }
      if (gameData.isdown) {

        if (gameData.oneClick === null) {
          gameData.oneClick = Happyo;
          outline1.clear();
          outline1.roundRect(gameData.oneClick.happy.x.valueOf()-(canvas.width/1.18/8.2)/2, gameData.oneClick.happy.y.valueOf()-(canvas.width/1.18/8.2)/2, canvas.width/1.18/8.2, canvas.width/1.18/8.2, 6);
          outline1.stroke({ width: canvas.width/180, color:color1 });
          outline1.alpha = 0;
          outline.addChild(outline1);
          gsap.to(outline1 , {
            alpha: 1,
            duration: 20 / 60,
            paused: false,
          });
        } else {
          if (gameData.oneClick === Happyo
              || gameData.oneClick.HL[0] - Happyo.HL[0] > 1
              || Happyo.HL[0] - gameData.oneClick.HL[0] > 1
              || gameData.oneClick.HL[1] - Happyo.HL[1] > 1
              || Happyo.HL[1] - gameData.oneClick.HL[1] > 1
              //|| gameData.oneClick.WLType === Happyo.WLType
          ) {

            gameData.oneClick = null;
            outline1.clear();
            gameData.double = 0;
            return;
          }

          gameData.twoClick = Happyo;
          outline2.clear();
          outline2.roundRect(gameData.twoClick.happy.x.valueOf()-(canvas.width/1.18/8.2)/2, gameData.twoClick.happy.x.valueOf()-(canvas.width/1.18/8.2)/2, canvas.width/1.18/8.2, canvas.width/1.18/8.2, 6);
          outline2.stroke({ width: canvas.width/180, color:color1 });
          outline2.alpha = 0;
          outline.addChild(outline2);
          gsap.to(outline2 , {
            alpha: 1,
            duration: 20 / 60,
            paused: false,
          });
          gameData.isdown = false;
          outline1.clear();
          outline2.clear();
          gsap.to(gameData.oneClick.happy, {
            x: gameData.twoClick.happy.x.valueOf(),
            y: gameData.twoClick.happy.y.valueOf(),
            duration: 20 / 60,
            paused: false,
          });
          gsap.to(gameData.twoClick.happy, {
            x: gameData.oneClick.happy.x.valueOf(),
            y: gameData.oneClick.happy.y.valueOf(),
            duration: 20 / 60,
            paused: false,
            onComplete: () => {
              const one = gameData.oneClick.HL;
              const two = gameData.twoClick.HL;
              gameData.twoClick.HL = one;
              gameData.oneClick.HL = two;


              //检测消除项
              Detectionelimintitem(gameData.oneClick);
              Detectionelimintitem(gameData.twoClick);
              if (gameData.elimintitems.length > 2) {
                elimintitem();

              }else {
                gsap.to(gameData.oneClick.happy, {
                  x: gameData.twoClick.happy.x.valueOf(),
                  y: gameData.twoClick.happy.y.valueOf(),
                  duration: 20 / 60,
                  paused: false,
                });
                gsap.to(gameData.twoClick.happy, {
                  x: gameData.oneClick.happy.x.valueOf(),
                  y: gameData.oneClick.happy.y.valueOf(),
                  duration: 20 / 60,
                  paused: false,
                })
                gameData.double =0;
                const one = gameData.oneClick.HL;
                const two = gameData.twoClick.HL;
                gameData.twoClick.HL = one;
                gameData.oneClick.HL = two;
              }

              gameData.isdown = true;
              gameData.oneClick = null;
              gameData.twoClick = null;
            }
          });
        }
      }
    });
    gameData.chessboard.container.addChild(happy);
    if (!init) {
      const happyee = getHappy(happy);
      happyee.rebirth.play();
    }

  }

  /** 检测清除*/
  function Detectionelimintitem(happy) {
    let type = happy.WLType;
    let Hlx = happy.HL[0];
    let Hly = happy.HL[1];
    let end = false;
    // console.log(type+"类型")
    let elimintd = [];
    for (let i = 1; i < 3; i++) {
      //横向检测
      if (Hlx - i >= 0) {
        const happye = HlgetHappy([Hlx - i, Hly]);
        if (happye !== null) {
          if (type === happye.WLType) {
            elimintd.push(happye);
            //console.log("向左");
            //console.log(elimintd);
            continue;
          }
        }
      }
      break;
    }

    for (let i = 1; i < 3; i++) {
      if (Hlx + i <= 8) {
        const happye = HlgetHappy([Hlx + i, Hly]);
        if (happye !== null) {
          if (type === happye.WLType) {
            elimintd.push(happye);
            //console.log("向右");
            // console.log(elimintd);
            continue;
          }
        }
      }
      break;
    }
    if (elimintd.length >= 2) {
      end = true;
      //console.log(elimintd);
      gameData.elimintitems = [...new Set([...gameData.elimintitems, ...elimintd])]
    }
    elimintd = [];
    for (let i = 1; i < 3; i++) {
      //竖向检测
      if (Hly - i >= 0) {
        const happye = HlgetHappy([Hlx, Hly - i]);
        if (happye !== null) {
          if (type === happye.WLType) {
            elimintd.push(happye);
            //console.log("向上");
            //console.log(elimintd);
            continue;
          }
        }
      }
      break;
    }

    for (let i = 1; i < 3; i++) {
      if (Hly + i <= 8) {
        const happye = HlgetHappy([Hlx, Hly + i]);
        if (happye !== null) {
          if (type === happye.WLType) {
            elimintd.push(happye);
            //console.log("向下");
            //console.log(elimintd);
            continue
          }
        }
      }
      break;
    }
    if (elimintd.length >= 2) {
      end = true;
      //console.log(elimintd);
      gameData.elimintitems = [...new Set([...gameData.elimintitems, ...elimintd])];
    }
    elimintd = [];
    if (end) {
      gameData.elimintitems.push(happy);
      // console.log(happy);
    }
  }

  /** 清除元素*/
  function elimintitem() {

    for (const happye of gameData.elimintitems) {
      gameData.chessboard.container.removeChild(happye.happy);
      gameData.texturesCount[happye.WLType]--;
    }
    if (gameData.oneClick!==null&& gameData.twoClick!==null){
      gameData.double++;
      addscore(gameData.elimintitems.length*2);
    }
    FallDetection();
  }

  /** 下落检测*/
  function FallDetection() {
    const xys = [];
    const xxs = [];
    /**待下移X坐标及其最低处*/
    const xhs = [];
    const xls = [];
    //最低处暂存
    let z = [1, 1];
    for (const happye of gameData.elimintitems) {
      xys.push(happye.HL);
      if (xxs.indexOf(happye.HL[0]) === -1) {
        xxs.push(happye.HL[0]);
      }
    }
    //计算涉及列最低处
    let i = 0;
    for (const x of xxs) {
      xhs.push(z);
      for (const xy of xys) {
        if (xy[0] === x) {
          if (xy[1] >= z[1]) {
            z = [x, xy[1]];
          }
        }
        xhs[i] = z;
      }
      z = [1, 1];
      i++;
    }
    i = 0;
    z = [8, 8];
    //计算涉及列最高处
    for (const x of xxs) {
      //console.log("x"+x);
      xls.push(z);
      for (const xy of xys) {
        if (xy[0] === x) {
          //console.log("xy[0]"+xy[0]);
          //console.log(xy[1]+"--"+z[1]);
          if (xy[1] <= z[1]) {

            z = [x, xy[1]]

            //console.log(x+"-"+xy[1]);
          }
        }
        xls[i] = z;
      }
      i++
    }
    i = 0;
    z = [1, 1];
    MovingDown(xxs, xhs, xls);

  }

  /**元素下移*/
  function MovingDown(xxs, xhs, xls) {
    //所有下落元素集合
    let FHS = [];
    //每一列下落高度
    let xzh = [];
    for (const xx of xxs) {
      //console.log("xx--"+ xx);
      let Xh = 0;
      let Xl = 0;
      for (let xh of xhs) {
        //console.log("xh--"+xh);
        if (xx === xh[0]) {
          Xh = xh[1];
          //console.log("Xh--"+Xh);
          break;
        }
      }
      for (let xl of xls) {
        //console.log("xl--"+xl);
        if (xx === xl[0]) {
          Xl = xl[1];
          //console.log("Xl--"+Xl);
          break;
        }
      }
      const zh = Xh - Xl + 1;


      const FHs = [];
      for (let i = Xl - 1; i > 0; i--) {
        FHs.push([xx, i]);
      }
      xzh.push([xx, zh]);
      //console.log(xzh);
      FHS = [...new Set([...FHS, ...FHs])]
      //console.log(FHS);
    }
    for (let Fhs of FHS) {
      let happye = HlgetHappy(Fhs);
      let Zh = 0;
      for (let zh of xzh) {
        if (zh[0] === happye.HL[0]) {
          Zh = zh[1];
        }
      }
      const s = gsap.to(happye.happy, {
        y: happye.happy.y.valueOf() + (happye.happy.height * Zh),
        duration: 20 / 60,
        paused: true,
      });
      s.play();
      happye.HL = [happye.HL[0], happye.HL[1] + Zh];

    }
    const CreateHappys = [];
    for (const xx of xxs) {
      let Zh = 0;
      for (let zh of xzh) {
        if (zh[0] === xx) {
          Zh = zh[1];
          break;
        }
      }
      for (let i = 1; i < Zh + 1; i++) {
        CreateHappys.push([xx, i]);
      }
    }
    //随机布局并放置快乐
    for (let i = 0; i < CreateHappys.length; i++) {
      const CHI = CreateHappys[i];
      const rh = RandomHappy();
      CreateHappy(canvas, rh, CHI, false);
    }
    gameData.elimintitems = [];
   //收集下落项和新建项 自动消除符合项目达成连击效果
    /*const AutoElmint = [...new Set([...FHS , ...CreateHappys])];
    console.log(AutoElmint);
    for (const Elmint of AutoElmint){
      const Happye = HlgetHappy(Elmint);
      Detectionelimintitem(Happye);
    }
    if (gameData.elimintitems>2){
      gameData.elimintitems = [...new Set(gameData.elimintitems)];
      console.log(gameData.elimintitems);
      //elimintitem();
    }*/
  }

  //获取指定happy元素  通过布局位置
  function getHappy(sprite) {
    for (let happye of gameData.chessboard.HappyEs) {
      if (happye.happy === sprite) {
        return happye;
      }
    }
    return null;
  }

  function HlgetHappy(hl) {
    for (let happye of gameData.chessboard.HappyEs) {
      if (happye.HL[0] === hl[0]) {
        if (happye.HL[1] === hl[1]) {
          return happye;
        }
      }
    }
    return null;
  }

  const filter = new Graphics();
  filter.rect(0 , 0 , canvas.width , canvas.height/1.2);
  filter.fill({ color: 0x000000, alpha: 0 });
  app.stage.addChild(filter);

  const button = await Assets.load("./property/buuton.png")
  const button1 = new Sprite(button);
  button1.anchor.set(0.5);
  button1.x = canvas.width / 2;
  button1.y = canvas.height /2;
  button1.width = canvas.width / 2.8;
  button1.height = canvas.width /2.8 / 2.5;
  button1.alpha = 0;
  app.stage.addChild(button1);
  button1.on("pointerdown" , ()=>{
    useLoginStore().NewName("default");
  })

  const ntegralText = new Text({
    text: "再玩一次",
  });
  ntegralText.anchor.set(0.5);
  ntegralText.x = canvas.width / 2;
  ntegralText.y = canvas.height / 2;
  ntegralText.style.fontSize = canvas.height / 18 / 2;
  ntegralText.style.fill = 0x000000;
  ntegralText.alpha =0;
  app.stage.addChild(ntegralText);
  ntegralText.on("pointerdown" , ()=>{
    useLoginStore().NewName("default");
  })
  const tegralText = new Text({
    text: "恭喜获胜",
  });
  tegralText.anchor.set(0.5);
  tegralText.x = canvas.width / 2;
  tegralText.y = canvas.height / 3.5;
  tegralText.style.fontSize = canvas.height / 10 / 2;
  tegralText.style.fill = 0xFFFFFF;
  tegralText.alpha = 0;
  app.stage.addChild(tegralText);

  const maxScore = 30;
  function addscore(score){
    let a = gameData.score+score + (gameData.double*2);

    if (a>=maxScore){
     /* gameData.isdown = false;
      filter.clear();
      filter.rect(0 , 0 , canvas.width , canvas.height);
      filter.fill({ color: 0x000000, alpha: 0.6 });
      button1.alpha = 1;
      ntegralText.alpha = 1;
      tegralText.alpha = 1;*/
      useLoginStore().NewName("End");
      a = maxScore;
    }
    gameData.score = a;
  }


  app.ticker.add(() => {
    gameData.frame = gameData.frame++;
    integralText.text = "分数：" + gameData.score;
    if (gameData.score >= maxScore){
      gameData.isdown = false;
      console.log(gameData.isdown);
    }
  });

}



</script>



<style scoped>

</style>