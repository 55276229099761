<template>
  <div id="LoginScreen"></div>
</template>
<script setup>

import {Application, Text, Assets, Container,  Graphics, Sprite,} from "pixi.js"
import {gsap} from "gsap"
import {useLoginStore} from "@/stores/APP-Srceen.ts";

//异步任务
(async () =>
    {
      let p = useLoginStore().AdaptationScreen();
      let isadd = useLoginStore().inside.valueOf();
      //创建pixi应用/画布
      const app = new Application();
      await app.init({
        width: isadd?1260+(1260*p):1260-(1260*p),    //画布宽度
        height: isadd?2800+(2800*p):2800-(2800*p), //画布高度
        /*width: window.innerWidth,    //画布宽度
        height: window.innerHeight, //画布高度*/
        background: {r:255 , g:246 , b:207},   //背景颜色
        backgroundAlpha: 1,  //背景透明度
        antialias: true,  //抗锯齿
      });
      //画布CSS属性配置
      const canvas = app.canvas;
      canvas.style.position = 'fixed';
      canvas.style.top = '50%';
      canvas.style.left = '50%';
      canvas.style.transform = 'translate(-50%, -50%)';
      //将画布添加到指定元素下
      //指定元素一定要被<Suspense> 包裹!!!!!!!!!!!!!!!!!!!!
      document.getElementById("LoginScreen").appendChild(app.canvas);

      //背景光芒
      const LoginFlameTexture = await Assets.load("./property/Login-Flame.png");
      const LoginFlame = new Sprite(LoginFlameTexture);
      LoginFlame.anchor.set(0.5);
      LoginFlame.x = canvas.width/2;
      LoginFlame.y = canvas.height/2;
      LoginFlame.width = canvas.height>canvas.width?canvas.height:canvas.width;
      LoginFlame.height = canvas.height>canvas.width?canvas.height:canvas.width;
      app.stage.addChild(LoginFlame);
      //背景光芒遮罩
      const FlameShade = new Graphics();
      let FlameShadeRadius = 0;
      FlameShade.circle(canvas.width/2, canvas.height/2 , 0.00001);
      FlameShade.fill({color:0x000000 , alpha:0});
      app.stage.addChild(FlameShade);
      LoginFlame.mask = FlameShade;
      //主体物
      //主体物整体
      const mainCon = new Container();
      app.stage.addChild(mainCon);
      //原味AD钙
      const OriginalTexture = await Assets.load("./property/Original.png");
      const Original = new Sprite(OriginalTexture);
      Original.anchor.set(0.5);
      Original.width = 0;
      Original.height = 0;
      Original.x = canvas.width/2;
      Original.y = canvas.height/2;
      mainCon.addChild(Original);

      const TweenVars = {
        x :canvas.width/3.4+(canvas.width/4.2/2),
        y: canvas.height/2.6+(canvas.height/4.7/2),
        width: canvas.width/4.2,
        height:canvas.height/4.7,
        duration: 1.2,
        ease: "bounce.out",
        paused: true,
      };
      let r = 0.2;
      gsap.to(Original, {
        rotation:r,
        duration: 0.3,
        ease: "bounce.out",
        paused:true,
        onComplete:(()=>{
          gsap.to(Original , {
            rotation:0,
            duration: 0.3,
            ease: "bounce.out",
          })
        })
      });
      const Originaljoin =  gsap.to(Original,TweenVars);

      //草莓AD钙
      const StrawberryTexture = await Assets.load("./property/Strawberry.png");
      const Strawberry = new Sprite(StrawberryTexture);
      Strawberry.anchor.set(0.5);
      Strawberry.width = 0;
      Strawberry.height =0;
      Strawberry.x = canvas.width/2;
      Strawberry.y = canvas.height/2;
      mainCon.addChild(Strawberry);
      const Strawberryjoin =  gsap.to(Strawberry, {
        x :canvas.width/2.1+(canvas.width/4/2),
        y: canvas.height/2.45+(canvas.height/4.6/2),
        width: canvas.width/4,
        height:canvas.height/4.6,
        duration: 1.4,
        ease: "bounce.out",
        paused: true
      });

      //引号集合
      const marks = new Container();
      marks.alpha = 0;
      app.stage.addChild(marks);
      //引号1
      const mark1Texture = await Assets.load("./property/mark1.png");
      const mark1 = new Sprite(mark1Texture);
      mark1.width = canvas.height/28;
      mark1.height = canvas.height/28;
      mark1.x = canvas.width/4.8;
      mark1.y = canvas.height/1.76;
      mark1.rotation=180;
      mark1.anchor.set(0.2 , 0.2)
      marks.addChild(mark1);
      const mark1join =  gsap.to(mark1, {
        rotation:176,
        duration:1.5,
        ease: "bounce.out",
        paused: true,
      });
      //引号2
      const mark2Texture = await Assets.load("./property/mark2.png");
      const mark2 = new Sprite(mark2Texture);
      mark2.width = canvas.height/28;
      mark2.height = canvas.height/28;
      mark2.x = canvas.width/1.46;
      mark2.y = canvas.height/2.38
      mark2.rotation=180;
      mark2.anchor.set(0.1 , 0.1);
      marks.addChild(mark2);
      const mark2join =  gsap.to(mark2, {
        rotation:182.4,
        duration:1.4,
        ease: "bounce.out",
        paused: true,
      });

      //加载标题
      const LoginTitleTexture = await Assets.load("./property/LoginTitle.png");
      const LoginTitle = new Sprite(LoginTitleTexture);
      LoginTitle.x = -canvas.width/5*4;
      LoginTitle.y = canvas.height/8.5+canvas.height/15;
      LoginTitle.width = canvas.width/5*4;
      LoginTitle.height = canvas.height/4.5;
      app.stage.addChild(LoginTitle);

      const LoginTitlejoin =  gsap.to(LoginTitle, {
        x:-5,
        y:canvas.height/8.5,
        width:canvas.width/5*4,
        height: canvas.height/4.5,
        duration: 1.4,
        ease: "bounce.out",
        paused: true
      });
      //进度条
      //进度条集
      const Progress  = new Container();
      Progress.alpha = 0;
      app.stage.addChild(Progress);
      Progress.on("pointerenter" , ()=>{
        color = 0x63C984;
        Progresss.clear();
        Progresss.roundRect(canvas.width/2-(canvas.width/1.2)/2, canvas.height/1.25, canvas.width/1.2, canvas.height/20, 16);
        Progresss.fill({ color: color, alpha: 1 });
      });
      Progress.on("pointerleave" , ()=>{
        Progresss.clear();
        color = 0x49AE6E;
        Progresss.roundRect(canvas.width/2-(canvas.width/1.2)/2, canvas.height/1.25, canvas.width/1.2, canvas.height/20, 16);
        Progresss.fill({ color: color, alpha: 1 });
        ProgressText.style.fill = 0x000000;
      });
      Progress.on("pointerdown" , ()=>{
        Progresss.clear();
        color = 0x00783E;
        Progresss.roundRect(canvas.width/2-(canvas.width/1.2)/2, canvas.height/1.25, canvas.width/1.2, canvas.height/20, 16);
        Progresss.fill({ color: color, alpha: 1 });
        ProgressText.style.fill = 0xFFFFFF;
      });
      Progress.on("pointerup" , ()=>{
        Progresss.clear();
        color = 0x49AE6E;
        Progresss.roundRect(canvas.width/2-(canvas.width/1.2)/2, canvas.height/1.25, canvas.width/1.2, canvas.height/20, 16);
        Progresss.fill({ color: color, alpha: 1 });
        ProgressText.style.fill = 0x000000;
        useLoginStore().NewName("Game");
      });
      //进度条
      const Progresss = new Graphics();
      let Progresssa =  0;
      let color = 0xFFFFFF;
      Progresss.roundRect(canvas.width/2-(canvas.width/1.2)/2, canvas.height/1.25, 0, canvas.height/20, 16);
      Progresss.fill({ color: color, alpha: 1 });
      Progress.addChild(Progresss);
      //进度条边框
      const ProgressM = new Graphics();
      ProgressM.roundRect(canvas.width/2-(canvas.width/1.2)/2, canvas.height/1.25, canvas.width/1.2, canvas.height/20, 16);
      ProgressM.stroke({ width: canvas.width/180, color: 0x000000 });
      Progress.addChild(ProgressM);
      //文本
      const ProgressText = new Text({
        text: "加载中...",
      });
      ProgressText.anchor.set(0.5);
      ProgressText.x = canvas.width/2;
      ProgressText.y = canvas.height/1.214;
      ProgressText.style.fontSize = canvas.height/20/2;
      ProgressText.style.fill = 0x000000;
      Progress.addChild(ProgressText);


      //动画区域
      {
        //gsap动画默认开始播放位置放这里没太多问题
        {
          gsap.timeline();
        }
        //全局时间轴
        //默认帧率  1秒60帧 也就是 每16.666毫秒执行一次
        //lastime  =  从网页打开到目前位置的毫秒级时间估算  网页打开时长
        //console.log(deta.lastTime);
        let frame = 0;
        app.ticker.add(() => {
          frame = frame+1;
          //背景光芒动画
          if (FlameShadeRadius<canvas.width*1.5){
            FlameShadeRadius = FlameShadeRadius+(canvas.width*1.5/(0.9*60));
            FlameShade.clear();
            FlameShade.circle(canvas.width/2, canvas.height/2 , FlameShadeRadius);
            FlameShade.fill({color:0x000000 , alpha:1});
          }
          //元素加载动画
          if (frame === 15){
            Strawberryjoin.play();
            Originaljoin.play();
            LoginTitlejoin.play();
          }else {
            if (frame>=15+(1.2*60)){
              /*if (frame-15+(1.2*60)%40===0){
                Originalleisure.play();
                r = r===0.2?-0.2:0.2;
                console.log(1)
              }*/
            }
          }
          if  (marks.alpha.valueOf()<1 && frame>=20){
            marks.alpha = marks.alpha+(1/15);
          }
          if (frame===25){
            mark1join.play();
            mark2join.play();
          }
          if (frame >= 40&& Progress.alpha<1){
            Progress.alpha = Progress.alpha+(1/20);
          }
          if (Progress.alpha>=1&&Progresssa<(canvas.width/1.2)){
            Progresss.clear();
            Progresssa = Progresssa+(canvas.width/1.2)/120;
            Progresss.roundRect(canvas.width/2-(canvas.width/1.2)/2, canvas.height/1.25, Progresssa, canvas.height/20, 16);
            Progresss.fill({ color: color, alpha: 1 });
          }else {
            if (Progresssa>=(canvas.width/1.2)&&ProgressText.text!=="开始游戏"){
              Progresss.clear();
              color = 0x49AE6E;
              Progresss.roundRect(canvas.width/2-(canvas.width/1.2)/2, canvas.height/1.25, canvas.width/1.2, canvas.height/20, 16);
              Progresss.fill({ color: color, alpha: 1 });
              ProgressText.text = "开始游戏"
              Progress.cursor = 'pointer';
              Progress.eventMode = 'dynamic';
              Progress.interactive = true;
            }
          }
          //元素空闲动画
        });
      }
    }

)();

</script>

<style scoped>

</style>