<template>
  <router-view :name="useLoginStore().Rname.valueOf()"> </router-view>
  <div :class="useLoginStore().Rname.valueOf()"></div>>
</template>

<script setup>
import {RouterView, useRouter} from 'vue-router'
import {onMounted} from "vue";
import {useLoginStore} from "@/stores/APP-Srceen.ts";



const router = useRouter();

//页面挂在后执行  基本处于页面加赞完成后执行
onMounted(() => {
  console.log("网页打开");
  console.log(useLoginStore().Rname.valueOf());
  router.push("/");

});
</script>

<style>
html, body {
  margin: 0;
  padding: 0;
  height: 100%;
}
#app{
  width: 100%;
  height: 100%; /* 100% of the viewport height */
  background-color:  rgb(255 , 246 , 207); /* Replace with your desired color */
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 2em;
}
</style>
